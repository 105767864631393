import React,{Component} from "react";
import Logo  from '../logo.jpg';
import { NavLink, Link } from "react-router-dom";
import Menu from "../Components/Menu";
class Synovisc extends Component
{
    render(){
        
        return ( 
   <>
            <div className="page-header">
        <header className="site-header">
            <Menu/>
        </header> 

        <div className="page-header-overlay">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <header className="entry-header">
                            <h1>Synovisc</h1>
                        </header> 
                    </div> 
                </div> 
            </div> 
        </div> 
    </div>
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="breadcrumbs">
                    <ul className="flex flex-wrap align-items-center p-0 m-0">
                        <li><a href="/"><i className="fa fa-home"></i> Home</a></li>
                      
                        <li>Synovisc</li>
                    </ul>
                </div> 
            </div> 
        </div> 
 
    </div>
    <section className="about-section">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-12 align-content-lg-stretch">
                    <header className="heading">
                        <h2 className="entry-title">Syno<span className="txtred">visc</span></h2>
                        
                    </header> 

                    
                </div> 

                <div className="col-12 col-lg-6 align-content-lg-stretch">
                       
                       
                        <p>Synovisc Injection is a medicine used in the treatment of osteoarthritis. It decreases the pain and swelling in the joints. It may be used alone or in combination with other medicines.</p>
                        
                        <p>Synovisc Injection is given by a healthcare professional. You should not self-administer this medicine at home. Use it regularly and do not stop taking the medicine even if you get better until that doctor tells you it is alright to stop.</p>
                        <p>The medicine is generally well tolerated with little or no side effects. It may cause pain, redness, and pain at the site of injection in some people. However, if you experience any symptoms which you think are due to the use of this medicine, you should let your doctor know. Your doctor may help with ways to reduce or prevent these symptoms.

</p>
<p>To make sure the medicine is safe for you, let your doctor know of all the other medicines you are taking before using this medicine. If you are pregnant or breastfeeding, you should consult your doctor first before taking this medicine.</p>
                        <p><b>USES OF Synovisc :</b> Osteoarthritis</p>
                         
                </div>
                <div className="col-12 col-lg-6 align-content-lg-stretch">
                <img src="../images/product2.png" style={{width:'100%' }}/>
                       
                </div>

                <div className="col-12 col-lg-12 align-content-lg-stretch">
                    <p><b>BENEFITS OF Synovisc  INJECTION</b></p>
                    <p className="greensmall">In Osteoarthritis</p>
                    <p>Osteoarthritis is a condition that causes pain and stiffness in the joints. Synovisc Injection helps relieve pain and reduces inflammation and swelling in your joints due to this condition. This will ensure that you have a better, more active, quality of life.</p>
                    <p>Additionally, you may consider simple lifestyle modifications such as regular exercise, losing weight, maintaining good posture while doing various daily activities, and wearing comfortable footwear. This will help you manage mild symptoms of osteoarthritis very effectively in the long run.</p>


                   <p><b>HOW TO USE SYNOVISC </b></p>
<p>Your doctor or nurse will give you this medicine. Kindly do not self administer.</p>
<p><b>HOW SYNOVISC  INJECTION WORKS</b></p>
<p>Synovisc  Injection is natural polysaccharide (sugar). In bone disorders (osteoarthritis), it works by reducing friction in the joints and helps them work smoothly.  Injection also has an antiageing effect. When injected into the skin (on the face most often), it works by hydrating the skin, enabling it to retain more water. This creates a filling/plumping effect and potentially fights wrinkles and other signs of ageing.</p>



                </div>

                <div className="col-12 col-lg-12 align-content-lg-stretch">
                   <p><b>SAFETY ADVICE</b></p> 
                </div>
                <div className="col-12 col-lg-4 align-content-lg-stretch">
                   <img src="../images/i4.png" />
                   <h4>Kidney</h4>
                   <p className="txtgreen">CONSULT YOUR DOCTOR</p>
                   <p>Information regarding the use of Injection during breastfeeding is not available. Please consult your doctor.</p>
                </div>

                <div className="col-12 col-lg-4 align-content-lg-stretch">
                   <img src="../images/i5.png" />
                   <h4>Liver</h4>
                   <p className="txtgreen">SAFE IF PRESCRIBED</p>
                   <p>Injection is probably safe to use in patients with liver disease. Limited data available suggests that dose adjustment of Synvisc Hylan GF 20mg Injection may not be needed in these patients. Please consult your doctor.</p>
                </div>

                <div className="col-12 col-lg-4 align-content-lg-stretch">
                   <img src="../images/i6.png" />
                   <h4>Alcohol</h4>
                   <p className="txtgreen">SAFE</p>
                   <p>Consuming alcohol with Synovisc  Injection does not cause any harmful side effects.</p>
                </div>

                <div className="col-12 col-lg-4 align-content-lg-stretch">
                   <img src="../images/i1.png" />
                   <h4>Pregnancy</h4>
                   <p className="txtgreen">CONSULT YOUR DOCTOR</p>
                   <p>Injection may be unsafe to use during pregnancy. Although there are limited studies in humans, animal studies have shown harmful effects on the developing baby. Your doctor will weigh the benefits and any potential risks before prescribing it to you. Please consult your doctor.</p>
                </div>

                <div className="col-12 col-lg-4 align-content-lg-stretch">
                   <img src="../images/i2.png" />
                   <h4>Breast feeding</h4>
                   <p className="txtgreen">CONSULT YOUR DOCTOR</p>
                   <p>Information regarding the use of Injection during breastfeeding is not available. Please consult your doctor.</p>
                </div>
                <div className="col-12 col-lg-4 align-content-lg-stretch">
                   <img src="../images/i3.png" />
                   <h4>Driving</h4>
                   <p className="txtgreen">CONSULT YOUR DOCTOR</p>
                   <p>It is not known whether Injection alters the ability to drive. Do not drive if you experience any symptoms that affect your ability to concentrate and react.</p>
                </div>
            </div> 
        </div>
    </section>
    

     </>    
        );
    }
}

export default Synovisc;