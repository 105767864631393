import React,{Component} from "react";
import Logo  from '../logo.jpg';
import { NavLink, Link } from "react-router-dom";
import Menu from "../Components/Menu";
class Ryzoceff extends Component
{
    render(){
        
        return ( 
   <>
            <div className="page-header">
        <header className="site-header">
            <Menu/>
        </header> 

        <div className="page-header-overlay">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <header className="entry-header">
                            <h1>Ryzocef</h1>
                        </header> 
                    </div> 
                </div> 
            </div> 
        </div> 
    </div>
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="breadcrumbs">
                    <ul className="flex flex-wrap align-items-center p-0 m-0">
                        <li><a href="/"><i className="fa fa-home"></i> Home</a></li>
                       
                        <li>Ryzocef</li>
                    </ul>
                </div> 
            </div> 
        </div> 
 
    </div>
    <section className="about-section">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-12 align-content-lg-stretch">
                    <header className="heading">
                        <h2 className="entry-title">Ryzo<span className="txtred">cef</span></h2>
                        
                    </header> 

                    
                </div> 

                <div className="col-12 col-lg-6 align-content-lg-stretch">
                  <p className="mt-5"><b> COMPOSITION</b><br/>
Each combipack contains:<br/>
(a) <b>Cefoperazone & Sulbactam for Injection</b><br/>

Each vial contains :<br/>
Cefoperazone Sodium IP (Sterile) eq. to Anhydrous Cefoperazone 1.0 gm<br/>
Sulbactam Sodium IP (Sterile) eq. to Sulbactam 0.5 gm
</p>
<p>(b) <b>1 Ampoule of Sterile Water for Injections IP</b><br/>
Each ampoule contains:<br/>
Sterile Water for Injections IP 10 ml
</p>
 
    
                       
                        <p><b>MECHANISM OF ACTION</b></p>
                        
                        <p>Cefoperazone, a third generation Cephalosporin acts by inhibiting biosynthesis of cell wall mucopeptide. Sulbactam acts as beta-lactamase inhibitor, thus restoring Cefoperazone activity against beta-lactamase producing strains.</p>
						
                         
                </div>
                <div className="col-12 col-lg-6 align-content-lg-stretch">
                <img src="../images/product3.png" style={{width:'100%' }}/>
                       
                </div>

                <div className="col-12 col-lg-12 align-content-lg-stretch">
                    <p><b>PHARMACOKINETICS</b></p>
                  
                    <p>Approximately 84% of the Sulbactam dose and 25% of the Cefoperazone dose administered as Sulbactam / Cefoperazone is excreted by the kidneys. Most of the remaining dose of Cefoperazone is excreted in the bile. After Sulbactam / Cefoperazone administration, the mean half-life for Sulbactam is about 1 hour while that for Cefoperazone is 1.7 hours. Serum concentrations have been shown to be proportional to the dose administered. These values are consistent with previously published values for these agents when given alone.</p>
                    <p>After intramuscular administration of 1.5 g Cefoperazone Sulbactam (0.5 g Sulbactam, 1 g Cefoperazone) peak serum concentrations of Sulbactam and Cefoperazone are seen from 15 minutes to 2 hours after administration. Mean peak serum concentrations were 19.0 and 64.2 mcg /mL for Sulbactam and Cefoperazone, respectively. Both Sulbactam and Cefoperazone distribute well into a variety of tissues and fluids, including the bile, gall bladder, skin, appendix, fallopian tubes, ovary, uterus, and others.</p>


                   <p><b>INDICATIONS</b></p>
<p>Indicated for the treatment of the following infections when caused by susceptible organisms:</p>
<ol>
<li>Respiratory tract infections (upper and lower)</li>
<li>Urinary tract infections (upper and lower)</li>
<li>Peritonitis, cholecystitis, cholangitis, and other intra-abdominal infections</li>
<li>Septicaemia</li>
<li>Meningitis</li>
<li>Skin and soft tissue infections</li>
<li>Bone and joint infections</li>
<li>Pelvic inflammatory disease, endometritis, gonorrhoea, and other infections of the genital tract.</li>

</ol>



                </div>

                <div className="col-12 col-lg-12 align-content-lg-stretch">
                   <p><b>DOSAGE AND ADMINISTRATION</b></p> 
				   <p><b>Adults</b></p>
				   <p>Daily dosage recommendations for Sulbactam / Cefoperazone in adults are as follows:</p>
				   
				   <table className="table">
					<tr>
						<td>Ratio</td>
						<td>Sulbactam/ Cefoperazone (g)</td>
						<td>Sulbactam activity (g)</td>
						<td>Cefoperazone activity (g)</td>
					</tr>
					<tr>
						<td>1:2 </td>
						<td>3.0–4.5</td>
						<td>1.0–1.5</td>
						<td>2.0–3.0</td>
					</tr>
				   </table>
				   <p>Doses should be administered every 12 hours. In severe or refractory infections, the daily dosage of Sulbactam / Cefoperazone may be increased upto 12 g of the 1:2 ratio (i.e. 8 g Cefoperazone activity).The recommended maximum daily dosage of Sulbactam is 4 g.</p>
				   
				   <p><b>Paediatric Use</b></p>
				   <p>Daily dosage recommendations for Sulbactam / Cefoperazone in children are as follows:</p>
				   
				   
				   <table className="table">
					<tr>
						<td>Ratio</td>
						<td>Sulbactam/ Cefoperazone mg/kg/day</td>
						<td>Sulbactam activity mg/kg/day</td>
						<td>Cefoperazone activity mg/kg/day</td>
					</tr>
					<tr>
						<td>1:2 </td>
						<td>30-60</td>
						<td>10-20</td>
						<td>20-40</td>
					</tr>
				   </table>
				   
				  <p>In serious or refractory infections, these dosages may be increased up to 240mg/kg/day (160 mg/kg/day Cefoperazone activity). Doses should be administered in two to four equally divided doses.</p> 
				  
				  <p><b>Use in Neonates</b></p>
				  <p>For neonates in the first week of life, the drug should be given every 12 hours. The maximum daily dosage of Sulbactam in paediatrics should not exceed 80 mg/kg/day.</p>
				  
				  <p><b>DIRECTIONS FOR USE</b></p>
				  <p>Dissolve the contents of vial in 10 ml of Sterile Water for Injections IP for IV use & 4 ml for IM use. The constituted solution should be used immediately after preparation.</p>
				  
				  
				  <p><b>CONTRAINDICATIONS</b></p>
				  <p>Contraindicated in patients with a known allergy to penicillins, Sulbactam, Cefoperazone, or any of the cephalosporins.</p>
				  
				 <p><b> WARNINGS & PRECAUTIONS</b></p>
<p>Cefoperazone should be cautiously administered to penicillin sensitive patients. Pseudomembranous colitis has been reported  with the use of Cephalosporins and other broad spectrum antibiotics. A disulfiram-like reaction reported when alcohol was ingested within 72 hours of Cefoperazone administration. Patients should be advised not to take alcohol with Cefoperazone.</p>

<p><b>DRUG INTERACTIONS</b></p>
<p>A reaction characterized by flushing, sweating, headache and tachycardia has been reported when alcohol was ingested during and as late as the fifth day after Cefoperazone administration. A similar reaction has been reported with certain other cephalosporins and patients should be cautioned concerning ingestion of alcoholic beverages in conjunction with
administration of Sulbactam / Cefoperazone. For patients requiring artificial feeding orally or parenterally, solutions containing ethanol should be avoided.</p>



<p><b>USE IN SPECIAL POPULATIONS</b></p>
<p>Pregnancy: There are no adequate and well-controlled studies in pregnant women. Because animal reproduction studies are not always predictive of human response, this drug should be used during pregnancy only if clearly needed.</p>
<p><b>Lactation:</b> Caution should be exercised when Sulbactam / Cefoperazone is administered to a nursing mother.</p>
<p><b>Paediatric Use:</b> There are no significant changes in the pharmacokinetics of the components of Sulbactam / Cefoperazone, compared to adult values. The mean half-life in children has ranged from 0.91 to 1.42 hours for Sulbactam and from 1.44 to 1.88 hours for Cefoperazone.</p>
<p><b>Geriatric Use :</b> Both Sulbactam and Cefoperazone exhibited longer half-life, lower clearance and larger volumes of distribution when compared to data from normal volunteers. The pharmacokinetics of sulbactam correlated well with the degree of renal dysfunction while for Cefoperazone, there was a good correlation with the degree of hepatic dysfunction.</p>


<p><b>UNDESIRABLE EFFECTS</b></p>
<p><b>Fixed drug eruption (FDE) has been reported with cephalosporin class formulations.</b></p>
<p>Sulbactam / Cefoperazone is generally well-tolerated. The majority of adverse events are of mild or moderate severity and are tolerated with continued treatment. The most frequent side effects observed with Sulbactam / Cefoperazone have been gastrointestinal. Others include dermatologic reactions, headache, injection pain, chills and anaphylactoid reactions.</p>


<p><b>OVERDOSAGE</b></p>
<p>Limited information is available on the acute toxicity of Cefoperazone sodium and Sulbactam sodium in humans. Overdosage of the drug would be expected to produce manifestations that are principally extensions of the adverse reactions reported with the drug. The fact that high cerebrospinal fluid concentrations of beta-lactam antibiotics may cause neurological effects,
including seizures, should be considered. Because Cefoperazone and Sulbactam are both removed from the circulation by haemodialysis, these procedures may enhance the elimination of the drug from the body if overdosage occurs in patients with impaired renal function.</p>

<p><b>STORAGE AND HANDLING INSTRUCTIONS</b></p>
<p>Store below 30°C. Protect from light & moisture. Do not freeze. Keep medicine out of reach of children.</p>

<p><b>PRESENTATION</b></p>
<p>Ryzocef Injection 1.5g is available in a vial and packed in mono carton with 10 ml Sterile Water for Injections IP</p>
<p><h4>Manufactured By : Akums Drugs & Pharmaceuticals Ltd.</h4></p>

 </div>
                 

                

                

                 

                 
                 
            </div> 
        </div>
    </section>
    

     </>    
        );
    }
}

export default Ryzoceff;