import React,{Component} from "react";
import Logo  from '../logo.jpg';
import { NavLink, Link } from "react-router-dom";
import Menu from "../Components/Menu";
class Zolanta extends Component
{
    render(){
        
        return ( 
   <>
            <div className="page-header">
        <header className="site-header">
            <Menu/>
        </header> 

        <div className="page-header-overlay">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <header className="entry-header">
                            <h1>Zolanta</h1>
                        </header> 
                    </div> 
                </div> 
            </div> 
        </div> 
    </div>
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="breadcrumbs">
                    <ul className="flex flex-wrap align-items-center p-0 m-0">
                        <li><a href="/"><i className="fa fa-home"></i> Home</a></li>
                        
                        <li>Zolanta</li>
                    </ul>
                </div> 
            </div> 
        </div> 
 
    </div>
    <section className="about-section">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-12 align-content-lg-stretch">
                    <header className="heading">
                        <h2 className="entry-title">Zol<span className="txtred">anta</span></h2>
                        
                    </header> 

                    
                </div> 

                <div className="col-12 col-lg-6 align-content-lg-stretch">
                  <p className="mt-5"><b> COMPOSITION</b><br/>
Each 100 ml contains::<br/>
Zoledronic Acid IP       5 mg<br/>
Water for Injections IP      q.s.<br/>
 
</p>
 
 
    
                       
                        <p><b>DESCRIPTION</b></p>
                        
                        <p>Zoledronic Acid Infusion contains zoledronic acid, a bisphosphonic acid which is an inhibitor of osteoclastic bone resorption. Zoledronic acid is designated chemically as (1-Hydroxy-2-imidazol-1-yl-phosphonoethyl) phosphonic acid monohydrate. Zoledronic acid monohydrate is a white crystalline powder. Its molecular formula is C5H10N2O7P2 H2O and a molar mass of 290.1 g/Mol. The pH of the Zoledronic Acid Infusion for infusion is approximately 6.0-7.0.</p>
						
                         
                </div>
                <div className="col-12 col-lg-6 align-content-lg-stretch">
                <img src="../images/product5.jpg" style={{width:'100%' }}/>
                       
                </div>

                <div className="col-12 col-lg-12 align-content-lg-stretch">
                    <p><b><u>CLINICAL PHARMACOLOGY</u></b></p>
                    <p><b>Mechanism of Action</b></p>
                  
                    <p>Zoledronic acid is a bisphosphonate and acts primarily on bone. It is an inhibitor of osteoclast-mediated bone resorption. Intravenously administered zoledronic acid rapidly partitions to bone and localizes preferentially at sites of high bone turnover. The main molecular target of zoledronic acid in the osteoclast is the enzyme farnesyl pyrophosphate synthase. The relatively long duration of action of zoledronic acid is attributable to its high binding affinity to bone mineral. Zoledronic Acid Infusion treatment on markers of bone resorption (serum beta-C-telopeptides [b-CTx]) and bone formation (bone specific alkaline phosphatase [BSAP], serum N-terminal propeptide of type I collagen [P1NP]) shows marked reduction to the pre-menopausal range with an approximate 55% reduction in b-CTx, a 29% reduction in BSAP and a 52 % reduction in P1NP over 36 months.</p>


                   <p><b>Pharmacokinetics</b></p>
<p>The post-infusion decline of zoledronic acid concentrations in plasma was consistent with a triphasic process showing a rapid decrease from peak concentrations at end-of-infusion to &lt; 1% of Cmax 24 hours post infusion with population half-lives of t1/2α 0.24 hour and t1/2β 1.87 hours for the early disposition phases of the drug. The terminal elimination phase of zoledronic acid was prolonged, with very low concentrations in plasma between Days 2 and 28 post infusion, and a terminal elimination half-life t1/2γ of 146 hours. The area under the plasma concentration versus time curve (AUC0-24h) of zoledronic acid was dose proportional from 2 to 16 mg.</p>
<p>Mean zoledronic acid protein binding in human plasma ranged from 28% at 200 ng/mL to 53% at 50 ng/mL. Zoledronic acid does not inhibit human P450 enzymes. Zoledronic acid does not undergo biotransformation and &lt; 3% of the administered intravenous dose was found in the feces, with the balance either recovered in the urine or taken up by bone, indicating that the drug is eliminated intact via the kidney. On average 39 ± 16% of the administered zoledronic acid dose was recovered in the urine within 24 hours, with only trace amounts of drug found in urine post Day 2. The cumulative percent of drug excreted in the urine over 0-24 hours was
independent of dose. The balance of drug not recovered in urine over 0-24 hours, representing drug presumably bound to bone, is slowly released back into the systemic circulation, giving rise to the observed prolonged low plasma concentrations. The 0-24 hour renal clearance of zoledronic acid was 3.7 ± 2.0 L/h.
</p>
 



                </div>

                <div className="col-12 col-lg-12 align-content-lg-stretch">
                   <p><b>Special Populations</b></p> 
				  
				   <p>Pediatrics: Zoledronic acid is not indicated for use in children.</p>
				   <p>Geriatrics: The pharmacokinetics of zoledronic acid was not affected by age ranged from 38 years to 84 years.</p>
				    
				   
				  <p>Hepatic Impairment: No clinical studies were conducted to evaluate the effect of hepatic impairment on the pharmacokinetics of zoledronic acid.</p> 
				  <p>Renal Impairment: No dosage adjustment is required in patients with a creatinine clearance of ≥ 35 mL/min. Zoledronic acid is not recommended for patients with severe renal impairment (creatinine clearance &lt; 35 mL/min).</p>

				  <p><b>INDICATIONS</b></p>
				  <p>Zoledronic Acid Infusion is indicated for the prevention and treatment of Osteoporosis in Postmenopausal Women, for the treatment to increase bone mass in men with osteoporosis, for the prevention and treatment of glucocorticoid-induced osteoporosis and for the treatment of paget's disease of bone.</p>
				  
				  <p><b>DOSAGE AND ADMINISTRATION</b></p>
				  <p>Treatment of Osteoporosis in Postmenopausal Women: The recommended regimen is a 5 mg infusion once a year given intravenously over no less than 15 minutes. For osteoporosis treatment, and to reduce the risk of hypocalcemia, patients must be adequately supplemented with calcium and vitamin D if dietary intake is not sufficient.</p>
				  
				  <p>Prevention of Osteoporosis in Postmenopausal Women: The recommended regimen is a 5 mg infusion given once every 2 years intravenously over no less than 15 minutes. Patients must be adequately supplemented with calcium and vitamin D if dietary intake is not sufficient.</p>
                  <p>Osteoporosis in Men: The recommended regimen is a 5 mg infusion once a year given intravenously over no less than 15 minutes. Patients must be adequately supplemented with calcium and vitamin D if dietary intake is not sufficient.</p>
                  <p>Treatment and Prevention of Glucocorticoid-Induced Osteoporosis: The recommended regimen is a 5 mg infusion once a year given intravenously over no less than 15 minutes. Patients must be adequately supplemented with calcium and vitamin D if dietary intake is not sufficient.
Treatment of Paget's Disease of Bone: The recommended dose is a 5 mg infusion. The infusion time must not be less than 15 minutes given over a constant infusion rate.
</p>
<p>Patients must be appropriately hydrated prior to administration of zoledronic acid. The i.v. infusion should be followed by a 10 mL normal saline flush of the intravenous line. Zoledronic acid solution for infusion must not be allowed to come in contact with any calcium or other divalent cation-containing solutions, and should be administered as a single intravenous solution through a separate vented infusion line. Administration of acetaminophen following zoledronic acid administration may reduce the incidence of acute-phase reaction symptoms. A 5 mg dose of zoledronic acid administered intravenously is recommended for patients with
creatinine clearance &gt; 35 mL/min.
</p>
				  
				 <p><b>CONTRAINDICATIONS</b></p>
<p>Zoledronic Acid Infusion is contraindicated in hypocalcemia and those who are hypersensitive to zoledronic acid or any other components of the formulation.</p>
<p>Carcinogenesis, Mutagenesis, Impairment of Fertility</p>
<p>Carcinogenesis: Rats were given daily oral doses of zoledronic acid of 0.1, 0.5, or 2.0 mg/kg/day. No increased incidence of tumors was observed (at doses ≤ 0.1 times the human intravenous dose of 5 mg, based on a mg/m2 comparison).</p>

<p>Mutagenesis: Zoledronic acid was not genotoxic in the Ames bacterial mutagenicity assay, in the Chinese hamster ovary cell assay, or in the Chinese hamster gene mutation assay, with or without metabolic activation. Zoledronic acid was not genotoxic in the in vivo rat micronucleus assay.</p>
<p>Impairment of Fertility: Female rats were given daily subcutaneous doses of zoledronic acid of 0.01, 0.03, or 0.1 mg/kg beginning 15 days before mating and continuing through gestation. Effects observed in the high-dose group (equivalent to human systemic exposure following a 5 mg intravenous dose, based on an AUC comparison) included inhibition of ovulation and a decrease in the number of pregnant rats. Effects observed in both the mid-dose group and high-dose group (0.3 to 1 times human systemic exposure following a 5 mg intravenous dose, based on an AUC comparison) included an increase in pre-implantation losses and a decrease in
the number of implantations and live fetuses.
</p>

<p><b>WARNING & PRECAUTIONS</b></p>
<p>Pre-existing hypocalcemia and disturbances of mineral metabolism (e.g., hypoparathyroidism, thyroid surgery, parathyroid surgery; malabsorption syndromes, excision of small intestine) must be effectively treated before initiating therapy with zoledronic acid. Clinical monitoring of calcium and mineral levels (phosphorus and magnesium) is highly recommended for these patients.</p>
<p>Hypocalcemia following zoledronic acid administration is a significant risk. All patients should be instructed about the symptoms of hypocalcemia and the importance of calcium and vitamin D supplementation in maintaining serum calcium levels.</p>
<p>Zoledronic acid should not be used in patients with severe renal impairment (creatinine clearance &lt; 35 mL/min) due to lack of adequate clinical experience in this population. A routine oral examination should be performed prior to initiation of bisphosphonate treatment. A dental examination with appropriate preventive dentistry should be considered prior to treatment with bisphosphonates in patients with a history of concomitant risk factors (e.g., cancer, chemotherapy, radiotherapy, corticosteroids, poor oral hygiene, pre-existing dental disease or infection, anemia, coagulopathy). While on treatment, patients with concomitant risk factors should avoid invasive dental procedures if possible. For patients who develop Osteonecrosis of jaw while on bisphosphonate therapy, dental surgery may exacerbate the condition.</p>

<p><b>DRUG INTERACTIONS</b></p>
<p>Caution is advised when bisphosphonates, including zoledronic acid, are administered with aminoglycosides, since these agents may have an additive effect to lower serum calcium level for prolonged periods.</p>


<p>Caution should also be exercised when zoledronic acid is used in combination with loop diuretics due to an increased risk of hypocalcemia.</p>


<p>Caution is indicated when zoledronic acid is used with other potentially nephrotoxic drugs such as nonsteroidal anti-inflammatory drugs.</p>

<p>Renal impairment has been observed following the administration of zoledronic acid in patients with pre-existing renal compromise or other risk factors. In patients with renal impairment, the exposure to concomitant medications that are primarily renally excreted (e.g., digoxin) may increase. Consider monitoring serum creatinine in patients at risk for renal impairment who are taking concomitant medications that are primarily excreted by the kidney.</p>

<p><b>USE IN PREGNANCY:</b></p>
<p>Pregnancy Category D </p>
<p>Zoledronic acid should not be used during pregnancy. It may cause fetal harm when administered to a pregnant woman. If the patient becomes pregnant while taking this drug, the patient should be apprised of the potential harm to the fetus. Women of childbearing potential should be advised to avoid becoming pregnant while on zoledronic acid therapy.</p>


<p><b>USE IN LACTATION</b></p>
<p>It is not known whether zoledronic acid is excreted in human milk. Because many drugs are excreted in human milk, and because it binds to bone long-term, zoledronic acid should not be administered to a nursing woman.</p>


<p><b>ADVERSE REACTIONS</b></p>

<p>Zoledronic acid is well tolerated, however in clinical trials adverse effects reported are as follows: Anemia, dehydration, anorexia, headache, dizziness, nausea, diarrhea, abdominal pain, dyspepsia, arthralgia, myalgia, fatigue, chills, fever etc.</p>


<p><b>OVERDOSE & ITS TREATMENT</b></p>
<p>Clinical experience with acute overdosage of zoledronic acid solution for intravenous infusion is limited. Patients who have received doses higher than those recommended should be carefully monitored. Overdosage may cause clinically significant renal impairment, hypocalcemia, hypophosphatemia, and hypomagnesemia. Clinically relevant reductions in serum levels of calcium, phosphorus, and magnesium should be corrected by intravenous administration of calcium gluconate, potassium or sodium phosphate, and magnesium sulfate, respectively.</p>

<p><b>STORAGE & HANDLING</b></p>
<p>Store at temperature 15°C-25°C.During Transportation permitted upto 30°C. Do not mix with calcium containing solutions. After opening the solution, it is stable for 24 hours at 2°C-8°C. If refrigerated, allow the refrigerated solution to reach room temperature before administration.</p>

 <p><h4>Manufactured By : Akums Drugs & Pharmaceuticals Ltd.</h4></p>
 </div>
                 

                

                

                 

                 
                 
            </div> 
        </div>
    </section>
    

     </>    
        );
    }
}

export default Zolanta;