import React,{Component} from "react";
import Logo  from '../logo.jpg';
import { NavLink, Link } from "react-router-dom";
import Menu from "../Components/Menu";
class CalwellHd extends Component
{
    render(){
        
        return ( 
   <>
            <div className="page-header">
        <header className="site-header">
            <Menu/>
        </header> 

        <div className="page-header-overlay">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <header className="entry-header">
                            <h1>Calwell-HD</h1>
                        </header> 
                    </div> 
                </div> 
            </div> 
        </div> 
    </div>
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="breadcrumbs">
                    <ul className="flex flex-wrap align-items-center p-0 m-0">
                        <li><a href="/"><i className="fa fa-home"></i> Home</a></li>
                        
                        <li>Calwell-HD</li>
                    </ul>
                </div> 
            </div> 
        </div> 
 
    </div>
    <section className="about-section">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-12 align-content-lg-stretch">
                    <header className="heading">
                        <h2 className="entry-title">Calwell<span className="txtred">-HD</span></h2>
                        
                    </header> 

                    
                </div> 

                <div className="col-12 col-lg-6 align-content-lg-stretch">
                  <p className="mt-5"><b> COMPOSITION</b><br/>
                  Coming Soon
 
</p>
 
 
    
                       
                        <p><b>DESCRIPTION</b></p>
                        
                        <p>Coming Soon</p>
						
                         
                </div>
                <div className="col-12 col-lg-6 align-content-lg-stretch">
                <img src="../images/product6.jpeg" style={{width:'100%' }}/>
                       
                </div>

                

 
                 

                

                

                 

                 
                 
            </div> 
        </div>
    </section>
    

     </>    
        );
    }
}

export default CalwellHd;