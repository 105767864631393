import React,{Component} from "react";
import Logo  from '../logo.jpg';
import { NavLink, Link } from "react-router-dom";
import Menu from "../Components/Menu";
class PTH extends Component
{
    render(){
        
        return ( 
   <>
            <div className="page-header">
        <header className="site-header">
            <Menu/>
        </header> 

        <div className="page-header-overlay">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <header className="entry-header">
                            <h1>PTH Pride</h1>
                        </header> 
                    </div> 
                </div> 
            </div> 
        </div> 
    </div>
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="breadcrumbs">
                    <ul className="flex flex-wrap align-items-center p-0 m-0">
                        <li><a href="/"><i className="fa fa-home"></i> Home</a></li>
                       
                        <li>PTH Pride</li>
                    </ul>
                </div> 
            </div> 
        </div> 
 
    </div>
    <section className="about-section">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-12 align-content-lg-stretch">
                    <header className="heading">
                        <h2 className="entry-title">PTH <span className="txtred">Pride</span></h2>
                        
                    </header> 

                    
                </div> 

                <div className="col-12 col-lg-6 align-content-lg-stretch">
                       
                       
                        <p>PTH PRIDE  Injection is a man-made version of parathyroid hormone used to treat men and postmenopausal women who have severe osteoporosis with a high risk of fractures. It works by stimulating new bone formation, which improves bone density and decreases the risk of spinal fractures.</p>
                        
                        <p>PTH PRIDE  Injection should be injected under the skin or given by a healthcare professional. You should use it regularly and at the same time each day to get the maximum benefit from it. Usually, it is recommended to take it for a maximum period of 2 years. However, follow the doctor’s advice and complete the course even if you feel better. It should be stored in the refrigerator at a temperature of  2°C to 8°C.</p>
                        <p>Before taking this medicine, let your doctor know if you have kidney stones or any other kidney problems, as it can cause an increase in the amount of calcium in blood or urine. Pregnant and breastfeeding mothers should also consult with their doctors before taking this medicine. Your doctor may advise a bone scan to monitor the progress of your treatment.</p>
                        <p><b>USES OF PTH PRIDE :</b> Osteoporosis</p>
                         
                </div>
                <div className="col-12 col-lg-6 align-content-lg-stretch">
                <img src="../images/product1.png" style={{width:'100%' }}/>
                       
                </div>

                <div className="col-12 col-lg-12 align-content-lg-stretch">
                    <p><b>BENEFITS OF PTH PRIDE  INJECTION</b></p>
                    <p className="greensmall">In Osteoporosis</p>
                    <p>Osteoporosis is a common condition that weakens bones, making them fragile and more likely to break.  Injection helps treat osteoporosis in men as well as in women after menopause. It slows down the rate at which bone is broken down in your body. This maintains bone density and reduces your risk of breaking a bone. The injection is given by a doctor or nurse and should not be self-administered. You can help improve the strength of your bones by doing regular exercise (especially weight-bearing exercise), eating healthy foods containing calcium and Vitamin D and reducing your consumption of alcohol and tobacco. Supplements of calcium and Vitamin D3 are also effective.</p>


                   <p><b> HOW TO USE PTH PRIDE</b></p>
<p>Your doctor or nurse will give you this medicine. Kindly do not self administer.</p>
<p><b>HOW PTH PRIDE WORKS</b></p>
<p>PTH PRIDE is a synthetic form of a natural human hormone called parathyroid hormone (PTH). It works by increasing the number and activity of bone-forming cells (osteoblasts). This strengthens the bones and minimizes the risk of fractures.</p>



                </div>
                
            </div> 
        </div>
    </section>
    

     </>    
        );
    }
}

export default PTH;