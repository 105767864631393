import React,{Component} from "react";
import Logo  from '../logo.jpg';
import { NavLink, Link } from "react-router-dom";
import Menu from "../Components/Menu";
class Tazowand extends Component
{
    render(){
        
        return ( 
   <>
            <div className="page-header">
        <header className="site-header">
            <Menu/>
        </header> 

        <div className="page-header-overlay">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <header className="entry-header">
                            <h1>Tazowand</h1>
                        </header> 
                    </div> 
                </div> 
            </div> 
        </div> 
    </div>
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="breadcrumbs">
                    <ul className="flex flex-wrap align-items-center p-0 m-0">
                        <li><a href="/"><i className="fa fa-home"></i> Home</a></li>
                     
                        <li>Tazowand</li>
                    </ul>
                </div> 
            </div> 
        </div> 
 
    </div>
    <section className="about-section">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-12 align-content-lg-stretch">
                    <header className="heading">
                        <h2 className="entry-title">Tazo<span className="txtred">wand</span></h2>
                        
                    </header> 

                    
                </div> 

                <div className="col-12 col-lg-6 align-content-lg-stretch">
                       <p className="mt-5"><b> COMPOSITION</b><br/>
Each combipack contains:<br/>
(a) <b>1 Vial of Piperacillin and Tazobactam Injection IP

</b><br/>

Each vial contains :<br/>
Piperacillin Sodium IP (Sterile) eq. to Piperacillin  4000 mg<br/>
Tazobactam Sodium IP (Sterile) eq. to Tazobactam  500 mg
</p>
<p>(b) <b>1 Ampoule of Sterile Water for Injections IP</b><br/>
Each ampoule contains:<br/>
Sterile Water for Injections IP  20 ml
</p>  

<h4>PHARMACOLOGY </h4> 
<p><b>Pharmacodynamics</b></p>
<p>Piperacillin sodium exerts bactericidal activity by inhibiting septum formation and cell wall synthesis of susceptible bacteria. In vitro, piperacillin is active against a variety of gram-positive and gram-negative aerobic and anaerobic bacteria. Tazobactam sodium has little clinically relevant in vitro activity against bacteria due to its reduced affinity to penicillin-binding proteins. It is, however, a beta-lactamase inhibitor of the Richmond-Sykes class III (Bush class 2b & 2b') penicillinases and cephalosporinases. It varies in its ability to inhibit class IIA and IV (2a & 4) penicillinases. Tazobactam does not induce chromosomally-mediated beta-lactamases at tazobactam concentrations achieved with the recommended dosage regimen.</p>
                      
                         
                </div>
                <div className="col-12 col-lg-6 align-content-lg-stretch">
                <img src="../images/product4.png" style={{width:'100%' }}/>
                       
                </div>

                <div className="col-12 col-lg-12 align-content-lg-stretch">
<p>Piperacillin/tazobactam has been shown to be active against most strains of the following microorganisms both in vitro and in clinical infections.</p>
<p><b>Aerobic and facultative Gram-positive microorganisms:</b></p>

<p>Staphylococcus aureus (excluding methicillin and oxacillin-resistant isolates)</p>
<p><b>Aerobic and facultative Gram-negative microorganisms:</b></p>

<p>Acinetobacter baumannii</p>
<p>Escherichia coli</p>
<p>Haemophilus influenzae (excluding beta-lactamase negative, ampicillin-resistant isolates)</p>
<p>Klebsiella pneumoniae</p>
<p>Pseudomonas aeruginosa (given in combination with an aminoglycoside to which the isolate is susceptible)</p>

<p><b>Gram-negative anaerobes:</b></p>
<p>Bacteroides fragilis group (B. fragilis, B. ovatus, B. thetaiotaomicron, and B. vulgatus)</p>


<p><b>Pharmacokinetics</b></p>
<p>Peak plasma concentrations of piperacillin and tazobactam are attained immediately after completion of an intravenous infusion of piperacillin/ tazobactam. Piperacillin plasma concentrations, following a 30-minute infusion of piperacillin/tazobactam, were similar to those attained when equivalent doses of piperacillin were administered alone, with mean peak plasma concentrations of approximately 134 and 298 μg/ml for the 2.25 g and 4.5 g piperacillin/tazobactam doses, respectively. The corresponding mean peak plasma concentrations of tazobactam were 15 and 34 μg/ml, respectively.</p>

<p>Piperacillin is metabolized to a minor microbiologically active desethyl metabolite.</p>

<p>Tazobactam is metabolized to a single metabolite that lacks pharmacological and antibacterial activities. Both piperacillin and tazobactam are eliminated via the kidney by glomerular filtration and tubular secretion. Piperacillin is excreted rapidly as unchanged drug with 68% of the administered dose excreted in the urine. Tazobactam and its metabolite are eliminated primarily by renal excretion with 80% of the administered dose excreted as unchanged drug and the remainder as the single metabolite. Piperacillin, tazobactam and desethyl piperacillin are also secreted into the bile. Both piperacillin and tazobactam are approximately 30% bound to plasma proteins. The protein binding of either piperacillin or tazobactam is unaffected by the presence of the other compound. Protein binding of the tazobactam metabolite is negligible. Piperacillin and tazobactam are widely distributed into tissues and body fluids including intestinal mucosa, gallbladder, lung, female reproductive tissues (uterus, ovary, and fallopian tube), interstitial fluid, and bile. Mean tissue concentrations are generally 50% to 100% of those in plasma. Distribution of piperacillin and tazobactam into cerebrospinal fluid is low in subjects with non-inflamed meninges, as with other penicillins.</p>

<h4>INDICATIONS</h4>

<p><b>Tazowand</b>  Injection is indicated for the treatment of patients with moderate to severe infections caused by piperacillin-resistant, piperacillin/tazobactam susceptible, beta -lactamase producing strains of the designated microorganisms in the specified conditions listed below:</p>

<p><b>Appendicitis (complicated by rupture or abscess) and peritonitis</b> caused by piperacillin-resistant, beta-lactamase producing strains of Escherichia coli or the following members of the Bacteroides fragilis group: B. fragilis, B. ovatus, B.thetaiotaomicron, or B. vulgatus.</p>

<p><b>Uncomplicated and complicated skin and skin structure infections, including cellulitis, cutaneous abscesses and ischemic/diabetic foot infections</b> caused by piperacillin-resistant, beta-lactamase producing strains of Staphylococcus aureus.</p>

<p><b>Postpartum endometritis or pelvic inflammatory disease</b> caused by piperacillin-resistant, beta-lactamase producing strains of Escherichia coli.

</p>

<p><b>Community-acquired pneumonia (moderate severity only)</b> caused by piperacillin-resistant, beta-lactamase producing strains of Haemophilus influenzae.</p>

<p><b>Nosocomial pneumonia (moderate to severe)</b> caused by piperacillin-resistant, betalactamase producing strains of Staphylococcus aureus and by piperacillin/tazobactam susceptible.</p>

<h4>DOSAGE AND ADMINISTRATION</h4>
<p>Tazowand should be administered by intravenous infusion over 30 minutes.</p>
<p><b>Adults:</b> The usual total daily dose of Tazowand is every six hours or as directed by the Physician.</p>
<p><b>Pediatrics</b></p>

<p>Tazowand can be administered in pediatric patients from 2 months of age. The dosage and the indications in pediatric patients with normal renal function are as follows:</p>

<table className="table">
	<tr>
		<td>Age of pediatric patients</td>
		<td>Infection/indication</td>
		<td>Recommended Dosage</td>
		 
	</tr>	
	<tr>
		<td>2 months and 9 months</td>
		<td>Community-acquired pneumonia, Intra-abdominal infections (appendicitis and/or peritonitis), Uncomplicated and complicated skin and skin structure infections, Pelvic inflammatory disease.</td>
		<td>80 mg piperacillin/10 mg tazobactam per kg q 8hr</td>
		 
	</tr>	
	<tr>
		<td>9 months or older
Weighing upto 40 kg
</td>
		<td>Nosocomial pneumonia</td>
		<td>100mg piperacillin/12.5 mg tazobactam per kg q 8hr</td>
		 
	</tr>	
	<tr>
		<td></td>
		<td></td>
		<td>3.375 gm q 6hr (12.0gm piperacillin/ 1.5gm tazobactam per day)</td>
		 
	</tr>	
	<tr>
		<td></td>
		<td></td>
		<td>4.5 gm q 6hr (16.0gm piperacillin / 2.0gm tazobactam per day)+aminoglycoside</td>
		 
	</tr>	
	 
</table>



<h4>Duration of Therapy</h4>
<p>The usual duration of Tazowand treatment is from seven to ten days. However, the recommended duration of Tazowand treatment of nosocomial pneumonia is 7 to 14 days. In all conditions, the duration of therapy should be guided by the severity of the infection and the patient's clinical and bacteriological progress.</p>
<p><b>Pediatrics:</b></p>
<p>There are no dosage recommendations for piperacillin/tazobactam in pediatric patients with impaired renal function.
</p>

<h4>CONTRAINDICATIONS</h4>
<p>Tazowand is contraindicated in patients with a history of allergic reactions to any of the penicillins, cephalosporins, or beta-lactamase inhibitors.
</p>

<h4>WARNINGS AND PRECAUTIONS</h4>


<p>Before initiating therapy with Tazowand, careful inquiry should be made concerning previous hypersensitivity reactions to penicillins, cephalosporins, or other allergens since serious and occasionally fatal hypersensitivity (anaphylactic) reactions have been reported in patients on penicillin therapy. If an allergic reaction occurs, Tazowand should be discontinued and appropriate therapy instituted.</p>
<p>
Pseudomembranous colitis has been reported with nearly all antibacterial agents, including piperacillin/tazobactam, and may range in severity from mild to life threatening. Therefore, it is important to consider this diagnosis in patients who present with diarrhoea subsequent to the administration of antibacterial agents.</p>
<p>
Mild cases of pseudomembranous colitis usually respond to drug discontinuation alone. In moderate to severe cases, consideration should be given to management with fluids and electrolytes, protein supplementation, and treatment with an antibacterial drug clinically effective against Clostridium difficile colitis.
</p>
<p>
Bleeding manifestations have occurred in some patients receiving beta-lactam antibiotics, including piperacillin. These reactions have sometimes been associated with abnormalities of coagulation tests such as clotting time, platelet aggregation, and prothrombin time, and are more likely to occur in patients with renal failure. If bleeding manifestations occur, Tazowand should be discontinued and appropriate therapy instituted.
</p>


<h4>Drug Interactions</h4>

<p>Aminoglycosides : The mixing of piperacillin/tazobactam with an aminoglycoside in vitro can result in substantial inactivation of the aminoglycoside. The Aminoglycoside should be reconstituted and administered separately.
</p>
<p>
Probenecid : Probenecid administered concomitantly with piperacillin/tazobactam prolongs the half-life of piperacillin by 21% and that of tazobactam by 71%.
</p>
<p>
Vancomycin : No pharmacokinetic interactions have been noted between piperacillin/tazobactam and vancomycin.
</p>
<p>
Vecuronium : Piperacillin when used concomitantly with vecuronium has been implicated in the prolongation of the neuromuscular blockade of vecuronium.
</p>
<p>
Methotrexate: Limited data suggests that co-administration of methotrexate and piperacillin may reduce the clearance of methotrexate due to competition for renal secretion. The impact of tazobactam on the elimination of methotrexate has not been evaluated. If concurrent therapy is necessary, serum concentrations of methotrexate as well as the signs and symptoms of methotrexate toxicity should be frequently monitored.
</p>
<p>
Heparin: Coagulation parameters should be tested more frequently and monitored regularly during simultaneous administration of high doses of heparin, oral anticoagulants, or other drugs that may affect the blood coagulation system or the thrombocyte function.
</p>

<p><b>Pregnancy</b></p>
<p>
Reproduction studies performed in animals have revealed no evidence of impaired fertility due to piperacillin/tazobactam administered up to a dose which is similar to the maximum recommended human daily dose based on body-surface area (mg/m 2).
</p>
<p>
<b>Lactation</b></p>
<p>Piperacillin is excreted in low concentrations in human milk; tazobactam concentrations in human milk have not been studied. Caution should be exercised when Tazowand is administered to a nursing woman.
</p>
<p>
<b>Pediatric Use</b></p>
<p>
Safety and efficacy in pediatric patients less than 2 months of age have not been established.
</p>
<p><b>
Geriatric Use</b></p>
<p>
Patients over 65 years are not an increased risk of developing adverse effects solely because of age. However, dosage should be adjusted in the presence of renal insufficiency.
</p>

<h4>ADVERSE DRUG REACTIONS</h4>
<ul>
	<li>Skin rashes including rash and pruritis</li>
	<li>Gastrointestinal including diarrhea, nausea and vomiting.</li>
	<li>Allergic reactions</li>
	<li>Hypokalaemia</li>
	<li>Bronchospasm</li>
	<li>Acute generalized exanthematous pustulosis (AGEP).</li>
</ul>

<h4>OVERDOSAGE</h4>

<p>There have been postmarketing reports of overdose with piperacillin/tazobactam. The majority of those events experienced, including nausea, vomiting, and diarrhoea, have also been reported with the usual recommended dosages.
</p>
<p>
Patients may experience neuromuscular excitability or convulsions if higher than recommended doses are given intravenously (particularly in the presence of renal failure).
</p>
<p>
Treatment should be supportive and symptomatic according the patient's clinical presentation. Excessive serum concentrations of either piperacillin or tazobactam may be reduced by haemodialysis. Following a single 3.375 g dose of piperacillin/tazobactam, the percentage of the piperacillin and tazobactam dose removed by haemodialysis was approximately 31% and 39%, respectively.
</p>

<h4>DIRECTION FOR USE</h4>

<p>
Constitute using 20 ml Sterile Water for Injections IP. The constituted solution should be used immediately after preparation.
</p>


<h4>STORAGE</h4>
<p><b>Store at controlled room temperature (20°C-25°C). Protect from light & moisture. Do not freeze.</b></p>
<p>Keep medicine out of reach of children.</p>

<h4>PRESENTATION</h4>
<p>
Tazowand Injection 4.5 g is available in a vial & packed in a mono carton.
</p>

<p><h4>Manufactured By : Akums Drugs & Pharmaceuticals Ltd.</h4></p>















                </div>

                
   
            </div> 
        </div>
    </section>
    

     </>    
        );
    }
}

export default Tazowand;