
import './App.css';

import Footer from './Components/Footer';
import Home from './Pages/Home';
import About from './Pages/About';
import Products from './Pages/Products';
import PTH from './Pages/PTH ';
import Synovisc from './Pages/Synovisc';
import Contact from './Pages/Contact';
import Career from './Pages/Career';
import Ryzoceff from './Pages/Ryzoceff';
import Tazowand from './Pages/Tazowand';
import Zolanta from './Pages/Zolanta';
import Neuromylin from './Pages/Neuromylin';
import Religel  from './Pages/Religel';
import CalwellXt from './Pages/CalwellXt';
import NAND from './Pages/NAND';
import CalwellHd from './Pages/CalwellHd';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
function App() {
  return (
    <div className="App">
      <Router>
     
        <Routes>
          <Route exact path='/' element={<Home/>} />
          <Route exact path='/about' element={<About/>} />
          <Route exact path='/products' element={<Products/>}/>
          <Route exact path='/pth-pride' element={<PTH/>}/>
          <Route exact path='/synovisc' element={<Synovisc/>}/>
          <Route exact path='/ryzoceff' element={<Ryzoceff/>}/>
          <Route exact path='/tazowand' element={<Tazowand/>}/>
          <Route exact path='/zolanta' element={<Zolanta/>}/>
          <Route exact path='/career' element={<Career/>}/>
          <Route exact path='/contact-us' element={<Contact/>}/>
          <Route exact path='/neuromylin-d' element={<Neuromylin/>}/>
          <Route exact path='/religel' element={<Religel/>}/>
          <Route exact path='calwell-xt' element={<CalwellXt/>}/>
          <Route exact path='nand' element={<NAND/>}/>

          <Route exact path='calwell-hd' element={<CalwellHd/>}/>
        </Routes>
        <Footer/>
       </Router>
    </div>
  );
}

export default App;
